var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":_vm.filters,"filter-principal":_vm.filterPrincipal,"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage,"send-multiple-sms":false},on:{"reload":function($event){return _vm.$refs['refClientsList'].refresh()},"onSelectChange":_vm.changeTypePayment},scopedSlots:_vm._u([{key:"custom-vselect",fn:function(){return [_c('FilterStatusAccount',{attrs:{"filters":_vm.filters[3]}})]},proxy:true}])},[_c('b-table',{ref:"refClientsList",attrs:{"slot":"table","small":"","no-provider-filtering":"","api-url":_vm.clientRoute,"items":_vm.myProvider,"fields":_vm.fieldsByTab,"primary-key":"id","table-class":"text-nowrap","responsive":"sm","show-empty":"","sticky-header":"70vh","busy":_vm.isBusy,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage},on:{"update:busy":function($event){_vm.isBusy=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(lead_name)",fn:function(data){return [(_vm.moduleId === 5)?_c('router-link',{attrs:{"to":{
            name: 'debt-solution-dashboard',
            params: {
              idClient: data.item.account_id,
            },
          },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):(_vm.moduleId === 3)?_c('router-link',{attrs:{"to":{
            name: 'bussiness-dashboard-clients',
            params: {
              idClient: data.item.account_id,
            },
          },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]):_c('router-link',{attrs:{"to":{
            name:
              _vm.moduleId == 5
                ? 'debt-solution-dashboard'
                : _vm.moduleId == 6
                ? 'credit-experts-dashboard'
                : _vm.moduleId == 7
                ? 'boost-credit-dashboard'
                : _vm.moduleId == 8
                ? 'tax-research-client-dashboard'
                : _vm.moduleId == 10
                ? 'claim-department-dashboard'
                : _vm.moduleId == 14
                ? 'bookeeping-dashboard'
                : null,
            params: {
              idClient: data.item.account_id,
            },
          },"target":"_blank"}},[_vm._v(_vm._s(data.item.lead_name))]),_c('div',[_vm._v(_vm._s(data.item.mobile))]),_c('status-account',{attrs:{"account":data.item,"text":true}}),_c('br'),(data.item.user_responsible)?_c('span',{staticClass:"text-warning"},[_c('tabler-icon',{attrs:{"icon":data.item.user_responsible === _vm.currentUser.user_id
                ? 'StarIcon'
                : 'LockIcon',"size":"15"}}),_c('strong',[_vm._v("CEO's client")])],1):_vm._e()]}},{key:"cell(users_comp)",fn:function(data){return [_c('div',[_c('ul',{staticStyle:{"padding-left":"0px","margin-bottom":"0px","list-style":"none"}},_vm._l((JSON.parse(data.item.users_comp)),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item.user_name)+" ")])}),0)])]}},{key:"cell(qty_services)",fn:function(ref){
                var item = ref.item;
return [_c('ClientPrograms',{attrs:{"clientData":item,"programName":item.program,"programId":item.program_id}})]}},(
          (_vm.moduleId != 2 && _vm.moduleId != 6) ||
          (_vm.moduleId == 6 && _vm.currentUser.role_id != 4)
        )?{key:"cell(charges)",fn:function(data){return [_c('div',[(_vm.moduleId != 2)?_c('span',[_vm._v("$ "+_vm._s(data.item.charges))]):_vm._e()])]}}:null,(
          (_vm.moduleId != 2 && _vm.moduleId != 6) ||
          (_vm.moduleId == 6 && _vm.currentUser.role_id != 4)
        )?{key:"cell(monthly_amount)",fn:function(data){return [_c('div',[(_vm.moduleId != 2)?_c('span',[_vm._v("$ "+_vm._s(data.item.monthly_amount))]):_vm._e()])]}}:null,(
          (this.moduleId != 2 && this.moduleId != 6) ||
          (this.moduleId == 6 && this.currentUser.role_id != 4)
        )?{key:"cell(last_payment)",fn:function(data){return [(_vm.moduleId != 2)?_c('div',[_c('span',{staticStyle:{"margin-left":"15px"}},[_c('b-img',{attrs:{"src":_vm.assetsImg + '/images/icons/lastpayment-out.ico'}}),_vm._v(" "+_vm._s(data.item.last_payment == "-" ? "" : data.item.last_payment)+" ")],1),(data.item.pending_payments == 1)?_c('div',{staticClass:"mp-pending"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"ml-5",staticStyle:{"margin-top":"5px"},attrs:{"icon":"CreditCardIcon","size":"18","title":"1 Monthly Payment Pending"}})],1):_vm._e()]):_vm._e()]}}:null,(
          (_vm.moduleId != 2 && _vm.moduleId != 6) ||
          (_vm.moduleId == 6 && _vm.currentUser.role_id != 4)
        )?{key:"cell(type_payment)",fn:function(data){return [(_vm.moduleId != 2)?_c('div',[_c('status-payment',{attrs:{"account":data.item}})],1):_vm._e()]}}:null,{key:"cell(advisor_name)",fn:function(data){return [_c('div',[_c('feather-icon',{style:(data.item.state_advisor == 1
                ? 'background:green'
                : data.item.state_advisor == 2
                ? 'background:orange'
                : data.item.state_advisor == 3
                ? 'background:red'
                : 'background:#ccc'),attrs:{"id":"icon","icon":"CircleIcon","size":"13"}}),_vm._v(" "+_vm._s(data.item.advisor_name)+" ")],1)]}},{key:"cell(created_at)",fn:function(data){return [_c('div',[_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.item.created_at)))])])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }